import React from "react";
import Header from "../components/layouts/header";
import css from "@emotion/css";

function Index() {
  return (
    <>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
        </div>
      </div>
      <section
        css={css`
          background-color: #e7eaea;
          min-height: calc(100vh - 600px);
          padding-left: 1rem;
          padding-right: 1rem;
        `}
      >
        <div className="container">
          <div className="row section-py">
            <h2>Thank you for your submission!</h2>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
